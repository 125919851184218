import Head from 'next/head'
import { useEffect, useState } from 'react'
import { Accordion } from 'react-accessible-accordion'
import { Fade } from 'react-awesome-reveal'
import removeMd from 'remove-markdown'

import { FaqAccordionItem } from '../../../atomic/FaqAccordionItem'
import styles from './faqs.module.scss'

const Faqs = ({ faqList }) => {
  const [activeFaqs, setActiveFaqs] = useState([])
  const [faqs, setFaqs] = useState(faqList.items.length > 0 ? faqList.items[0].content.items : [])

  useEffect(() => {
    if (faqList.items.length > 0) {
      setFaqs(faqList.items[0].content.items)
    }
  }, [faqList])

  return (
    <section className={styles.faqs}>
      <Head>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [${faqs.map(faq => {
                return JSON.stringify({
                  '@type': 'Question',
                  name: `${faq.title}`,
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text: `${removeMd(faq.body)}`,
                  },
                })
              })}]
            }
          `}
        </script>
      </Head>
      <div className={styles.grid}>
        <Fade triggerOnce>
          <Accordion
            allowZeroExpanded={true}
            preExpanded={activeFaqs}
            onChange={value => setActiveFaqs(value)}>
            {faqs.length > 0 &&
              faqs.map(faq => (
                <FaqAccordionItem
                  item={faq}
                  key={faq.slug}
                  isActive={activeFaqs.includes(faq.slug)}
                />
              ))}
          </Accordion>
        </Fade>
      </div>
    </section>
  )
}

export default Faqs
